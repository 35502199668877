"use strict";

// tohle nahraje i soubory, ktere nejsou primo linkovane z entrypointu
import "./images/*.{png,jpg,jpeg,gif,svg}";

// import stylu
import "./index.scss";

// NodeList.prototype.forEach() polyfill
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

// galerie SimpleLightbox - BEGIN
// https://simplelightbox.com
import SimpleLightbox from "simplelightbox";
import "simplelightbox/src/simple-lightbox.scss";
window.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll("*[data-gallery]").forEach((gallery) => {
    new SimpleLightbox("#" + gallery.getAttribute("id") + " a", {
      /* options */
    });
  });
});
// galerie SimpleLightbox - BEGIN

// clickToggleClass - BEGIN
window.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll("*[data-click-toggle-class]").forEach((elm) => {
    elm.addEventListener("click", (event) => {
      event.preventDefault();
      const classes = event.target.dataset.clickToggleClass.split("|");
      const targets = event.target.dataset.clickToggleTarget
        ? [...document.querySelectorAll(event.target.dataset.clickToggleTarget)]
        : [event.target];
      const toggledClases = targets[0].classList.contains(classes[0])
        ? {
            remove: classes[0],
            add: classes[1],
          }
        : {
            remove: classes[1],
            add: classes[0],
          };
      targets.forEach((elm) => {
        elm.classList.remove(toggledClases.remove);
        elm.classList.add(toggledClases.add);
      });
    });
  });
});
// clickToggleClass - END

/* MODALni oKNO CHARITA */

// Funkce pro získání hodnoty cookie podle jejího názvu
function getCookie(name) {
  let match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
  if (match) {
    return match[2];
  } else {
    return null;
  }
}

// Funkce pro zobrazení modálního okna
function showModal() {
  const modal = document.getElementById("modal");
  modal.style.display = "flex";

  // Uložení informace do cookie
  document.cookie = "modalOpened=true; path=/;";
}

// Zavření modálního okna
function closeModal() {
  const modal = document.getElementById("modal");
  modal.style.display = "none";
}

document.addEventListener("DOMContentLoaded", function () {
  // Zkontrolujeme, zda byla již modální okno otevřeno
  if (!getCookie("modalOpened")) {
    // Zobrazí modální okno po 2 vteřinách
    setTimeout(showModal, 2000);
  }

  // Získání prvků pro zavření modálního okna
  const closeBtn = document.querySelector(".close");
  const closeModalBtn = document.querySelector(".close-btn");

  // Zavření modálního okna při kliknutí na tlačítko zavřít
  closeBtn.addEventListener("click", closeModal);
  closeModalBtn.addEventListener("click", closeModal);

  // Zavření modálního okna při kliknutí mimo obsah okna
  window.addEventListener("click", function (event) {
    const modal = document.getElementById("modal");
    if (event.target === modal) {
      closeModal();
    }
  });
});
